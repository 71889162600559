export const metatags = {
        "Song Structure": ["Intro", "Verse", "Pre-Chorus", "Chorus", "Post-Chorus", "Bridge", "Outro", "Hook", "Break", "Fade Out", "Fade In"],
        "Instrumental": ["Instrumental", "Guitar Solo", "Piano Solo", "Drum Solo", "Bass Solo", "Instrumental Break"],
        "Vocal": ["Male Vocal", "Female Vocal", "Duet", "Choir", "Spoken Word", "Harmonies", "Vulnerable Vocals", "Whisper"],
        "Specific Elements": ["Catchy Hook", "Emotional Bridge", "Powerful Outro", "Soft Intro", "Melodic Interlude", "Percussion Break"],
        "Atmosphere and Mood": ["Eerie Whispers", "Ghostly Echoes", "Ominous Drone", "Spectral Melody", "Melancholic Atmosphere", "Euphoric Build", "Tense Underscore", "Serene Ambience", "Nostalgic Tones"],
        "Dynamic and Progression": ["Building Intensity", "Climactic", "Emotional Swell", "Layered Arrangement", "Orchestral Build", "Stripped Back", "Sudden Break", "Crescendo", "Decrescendo"],
        "Rhythm and Tempo": ["Four-on-the-Floor", "Syncopated Rhythm", "Breakbeat Rhythm", "Polyrhythmic Percussion", "Tempo Change", "Steady Beat", "Tempo Lock", "Rhythmic Anchor"],
        "Production and Effect": ["Reverb Heavy", "Distorted Guitar", "Synth Arpeggio", "Drum Machine", "Sidechain Compression", "Stereo Widening", "Dynamic EQ", "Multiband Processing"],
        "Narrative and Structural": ["Story Intro", "Character Theme", "Plot Development", "Climactic Build", "Narrative Resolution", "Plot Twist", "Surprise Ending", "Key Change"],
        "Specific Sound": ["Heart-Wrenching Strings", "Bittersweet Harmony", "Pulsing Synth", "Crowd Chant", "Bass Drop", "Unexpected Instrument"],
        "Cultural and Regional": ["Celtic Fiddle", "Bollywood Strings", "Flamenco Guitar", "Gamelan Ensemble"],
        "Style": {
            "Danceable": ["Dance", "Festive", "Groovy", "Mid-Tempo", "Syncopated", "Tipsy"],
            "Dark": ["Atmospheric", "Cold", "Dark", "Doom", "Dramatic", "Sinister"],
            "Eclectic": ["Adjunct", "Art", "Capriccio", "Mellifluous", "Nü", "Progressive", "Unusual"],
            "Emotion": ["Anthemic", "Emotional", "Happy", "Jubilant", "Melancholy", "Sad"],
            "Hard": ["Aggressive", "Banger", "Power", "Stadium", "Stomp"],
            "Lyrical": ["Broadway", "Cabaret", "Lounge", "Operatic", "Storytelling", "Torch-Lounge", "Theatrical", "Troubadour", "Vegas"],
            "Magical": ["Ethereal", "Majestic", "Mysterious"],
            "Minimal": ["Ambient", "Cinematic", "Heat", "Minimal", "Slow", "Sparse"],
            "Party": ["German Schlager", "Glam", "Glitter", "Groovy"],
            "Soft": ["Ambient", "Bedroom", "Chillwave", "Ethereal", "Intimate", "Heat", "Sadcore"],
            "Weird": ["Carnival", "Distorted", "Glitchy", "Haunted", "Hollow", "Musicbox", "Random"],
            "World/Ethnic": ["Arabian", "Bangra", "Calypso", "Chalga", "Egyptian", "Hindustani", "Hōgaku", "Jewish Music", "Klezmer", "Matsuri", "Middle East", "Polka", "Russian Navy Song", "Suomipop", "Tribal"]
        },
       "Genre": {
            "Classical": ["Andalusian classical", "Indian classical", "Korean court", "Persian classical", "Western classical", "Early music", "Medieval", "Renaissance", "Baroque", "Galant", "Romantic", "Modernism", "Impressionism", "Neoclassicism", "Postmodern", "Experimental", "Contemporary classical", "Minimal"],
            "Country": ["Appalachian", "Bluegrass", "Country", "Folk", "Freak Folk", "Western", "Alternative country", "Americana", "Cowpunk", "Gothic country", "Roots rock", "Australian country", "Bakersfield sound", "Blue Yodeling", "Bro-country", "Cajun", "Canadian country", "Christian country", "Classic country", "Country and Irish", "Country en Español", "Country folk", "Country pop", "Country rap", "Country rock", "Dansband", "Gulf and Western", "Honky tonk", "Instrumental country", "Nashville sound", "Neotraditional country", "Old-time", "Outlaw country", "Progressive country", "Regional Mexican", "Rockabilly", "Southern rock", "Sertanejo", "Talking blues", "Traditional country", "Truck-driving country", "Western swing", "Zydeco"],
            "Dance": ["Afro-Cuban", "Dance Pop", "Disco", "Dubstep", "Disco Funk", "EDM", "Electro", "High-NRG", "House", "Trance", "Electro House", "Electroclash", "Bubblegum Bass", "Breakbeat", "Baltimore club", "Jersey club", "Philly club", "Big beat", "Breakbeat hardcore", "Broken beat", "Florida breaks", "Nu skool breaks", "Progressive breaks"],
            "Downtempo": ["Ambient", "Downtempo", "Synthwave", "Trap", "Chill-out", "Trip hop", "Trip rock"],
            "Electronic": ["Ambient", "Cyberpunk", "Drum'n'bass", "Dubstep", "Electronic", "Hypnogogical", "IDM", "Phonk", "Synthpop", "Techno", "Trap", "Electropop", "Electronic Dance Music", "Electroacoustic", "Musique concrète", "Glitch", "Microsound", "Plunderphonics", "Hauntology", "Vaporwave"],
            "Jazz/Soul": ["Bebop", "Gospel", "Electro", "Frutiger Aero", "Jazz", "Latin Jazz", "RnB", "Soul", "Acid jazz", "Nu jazz", "Jazztronica"],
            "Latin": ["Bossa Nova", "Latin Jazz", "Forró", "Mambo", "Salsa", "Tango", "Bachata", "Corrido", "Mariachi", "Brazilian Funky"],
            "Reggae": ["Afrobeat", "Dancehall", "Dub", "Reggae", "Reggaeton"],
            "Metal": ["Black Metal", "Deathcore", "Death Metal", "Heavy Metal", "Heavy Metal Trap", "Metalcore", "Nu Metal", "Power Metal", "Synth-metal", "Electrogrind", "Electronicore"],
            "Popular": ["Pop", "Dance Pop", "Pop Rock", "Kpop", "Jpop", "RnB", "Synthpop", "Electropop", "Adult contemporary", "Easy listening", "Lounge"],
            "Rock": ["Classic Rock", "Blues Rock", "Emo", "Glam Rock", "Hardcore Punk", "Indie", "Industrial Rock", "Punk", "Rock", "Skate Rock", "Skatecore", "Suomipop", "Progressive Rock", "Jazz-Rock", "Canterbury Scene", "Electronic rock", "Dance-rock", "Alternative dance", "New wave", "Post-rock", "Space rock", "Synth-punk"],
            "Urban": ["Funk", "Electro", "HipHop", "RnB", "Phonk", "Rap", "Trap", "Crunk", "Snap music", "Miami bass"],
            "Blues": ["African blues", "Blues rock", "Blues shouter", "British blues", "Canadian blues", "Chicago blues", "Classic female blues", "Country blues", "Delta blues", "Desert blues", "Electric blues", "Gospel blues", "Jump blues", "Punk blues", "Soul blues", "Swamp blues", "Talking blues"],
            "Avant-garde & Experimental": ["Crossover music", "Danger music", "Drone music", "Lo-fi", "Musical improvisation", "Noise", "Outsider music", "Progressive music", "Psychedelic music", "Underground music"],
            "Type": ["Background", "Elevator", "Jingle", "Muzak"],
            "Call to Prayer": ["Adan", "Adjan", "Call to Prayer"],
            "Gregorian Chant": ["Gregorian Chant"],
            "Character": ["I Want Song", "Hero Theme", "Strut", "March", "Military", "Villain Theme"],
            "Children": ["Lullaby", "Nursery Rhyme", "Sing-along", "Toddler"],
            "Composer": ["Adagio", "Adjunct", "Andante", "Allegro", "Capriccio"],
            "Instruments": ["Acoustic Guitar", "Bass", "Doublebass", "Electricbass", "Electric Guitar", "Fingerstyle Guitar", "Percussion", "Noise"],
            "Noise": ["Chaotic", "Distorted", "Glitch", "Noise", "Random", "Stuttering", "Harsh noise", "Power electronics"],
            "Orchestral": ["Glissando Trombone", "Legato Cello", "Orchestral", "Spiccato Violins", "Staccato Viola", "Symphonic"],
            "Retro": ["1960s", "Barbershop", "Big Band", "Classic", "Doo Wop", "Girl Group", "Mambo", "Salooncore", "Swing", "Traditional"],
            "Traditional": ["Americana", "Barbershop", "Christmas Carol", "Traditional"],
            "Voice": ["A Cappella", "Arabian Ornamental", "Dispassionate", "Emotional", "Ethereal", "Gregorian chant", "Hindustani", "Lounge Singer", "Melismatic", "Monotone", "Narration", "Resonant", "Spoken Word", "Sprechgesang", "Sultry", "Scream", "Torchy", "Vocaloid"],
            "Ethnic Electronic": ["Asian Underground", "African electronic dance music", "Afrobeats", "Budots", "Changa tuki", "Denpa music", "Funk carioca", "Merenhouse", "Nortec", "Russ music", "Shamstep", "Tecnocumbia", "Tribal guarachero", "Worldbeat"]
},
         "Artists": {
"AC/DC": ["Hard Rock", "Stomp"],
"Achille Lauro": ["Trap", "Rock", "Eccentric", "Male Vocals"],
"Adele": ["Soul", "Emotional", "Torch-Lounge", "Female Vocals"],
"Adriano Celentano": ["Rock and Roll", "Pop", "Eclectic", "Male Vocals"],
"Aerosmith": ["Rock", "Hard Rock", "Classic"],
"Alabama Shakes": ["Rock", "Blues Rock", "Soulful"],
"Alice in Chains": ["Grunge", "Dark", "Melodic"],
"Amy Winehouse": ["Soul", "Jazz", "Torch-Lounge", "Female Vocals"],
"Annalisa": ["Pop", "Dance", "Female Vocals"],
"Antonello Venditti": ["Singer-Songwriter", "Pop Rock", "Male Vocals"],
"Aphex Twin": ["IDM", "Electronic", "Experimental", "Male Vocals"],
"Arctic Monkeys": ["Indie Rock", "Garage", "Cool"],
"Aretha Franklin": ["Soul", "Gospel", "Powerful", "Female Vocals"],
"Ariana Grande": ["Pop", "Dance Pop", "Ethereal", "Female Vocals"],
"Avicii": ["EDM", "Melodic", "Euphoric", "Male Vocals"],
"Baby K": ["Pop Rap", "Urban", "Female Vocals"],
"Bad Bunny": ["Reggaeton", "Latin Trap", "Urban", "Male Vocals"],
"Beyoncé": ["RnB", "Anthemic", "Danceable", "Female Vocals"],
"Billie Eilish": ["Pop", "Dark", "Minimal", "Female Vocals"],
"Billy Joel": ["Pop", "Rock", "Storytelling", "Male Vocals"],
"Björk": ["Alternative", "Experimental", "Unusual", "Female Vocals"],
"Black Sabbath": ["Heavy Metal", "Doom"],
"Blanco": ["Pop", "Urban", "Emotional", "Male Vocals"],
"Blink-182": ["Emo Pop Rock", "Male Vocals"],
"Boards of Canada": ["IDM", "Downtempo", "Nostalgic"],
"Bob Dylan": ["Folk", "Storytelling", "Acoustic Guitar", "Male Vocals"],
"Bob Marley": ["Reggae", "Peaceful", "Soulful", "Male Vocals"],
"Bon Iver": ["Indie Folk", "Ethereal", "Intimate", "Male Vocals"],
"Bon Jovi": ["Rock", "Anthem", "Stadium"],
"Bonobo": ["Downtempo", "Electronic", "Ambient"],
"Boomdabash": ["Reggae", "Pop", "Dancehall"],
"Brittany Howard": ["Rock", "Soul", "Powerful", "Female Vocals"],
"Brockhampton": ["HipHop", "Alternative", "Collective"],
"Bruno Mars": ["Funk", "Dance Pop", "Groovy", "Male Vocals"],
"Burial": ["Dubstep", "Ambient", "Mysterious"],
"Calcutta": ["Indie Pop", "Melancholic", "Male Vocals"],
"Calvin Harris": ["EDM", "Dance", "Festive", "Male Vocals"],
"Camila Cabello": ["Pop", "Latin Jazz", "Festive", "Female Vocals"],
"Caparezza": ["Rap", "Alternative", "Lyrical", "Male Vocals"],
"Cardi B": ["Rap", "Aggressive", "Party", "Female Vocals"],
"Caribou": ["Electronic", "Psychedelic", "Dance"],
"Cesare Cremonini": ["Pop Rock", "Melodic", "Male Vocals"],
"Childish Gambino": ["HipHop", "Funk", "Thoughtful", "Male Vocals"],
"Coez": ["Indie Pop", "Urban", "Male Vocals"],
"Coldplay": ["Alternative Rock", "Atmospheric"],
"Daft Punk": ["Electronic", "Dance", "Futuristic"],
"Dargen D'Amico": ["Rap", "Electronic", "Eclectic", "Male Vocals"],
"David Bowie": ["70s British Rock", "Art", "Eclectic", "Male Vocals"],
"Deadmau5": ["Electronic", "House", "Progressive"],
"Depeche Mode": ["Synthpop", "New Wave", "Dark"],
"Dolly Parton": ["Country", "Storytelling", "Female Vocals"],
"Drake": ["HipHop", "Trap", "Male Vocals"],
"Dua Lipa": ["Disco", "Dance Pop", "Groovy", "Female Vocals"],
"Ed Sheeran": ["Folk", "Acoustic Guitar", "Male Vocals"],
"Elodie": ["Pop", "Urban", "Female Vocals"],
"Elton John": ["Piano Pop Rock", "Theatrical", "Male Vocals"],
"Elvis Presley": ["50s Rock", "Hero Theme", "Male Vocals"],
"Emma Marrone": ["Pop", "Rock", "Powerful Voice", "Female Vocals"],
"Empire of the Sun": ["Electronic", "Pop", "Theatrical"],
"Enya": ["New Age", "Ethereal", "Calm", "Female Vocals"],
"Ermal Meta": ["Pop Rock", "Melodic", "Male Vocals"],
"Eros Ramazzotti": ["Pop Rock", "Romantic", "Male Vocals"],
"Fabrizio De André": ["Folk", "Storytelling", "Poetic", "Male Vocals"],
"Fedez": ["Rap", "Pop", "Commercial", "Male Vocals"],
"FKA Twigs": ["RnB", "Electronic", "Avant-Garde", "Female Vocals"],
"Fleetwood Mac": ["Classic Rock", "Mellifluous"],
"Florence + The Machine": ["Indie Rock", "Dramatic", "Ethereal"],
"Flying Lotus": ["Electronic", "Experimental HipHop", "Fusion", "Male Vocals"],
"Foo Fighters": ["Rock", "Alternative", "Energetic"],
"Four Tet": ["Electronic", "Ambient", "Textural"],
"Francesca Michielin": ["Pop", "Indie", "Female Vocals"],
"Francesco Guccini": ["Folk", "Poetic", "Storytelling", "Male Vocals"],
"Franco Battiato": ["Art Rock", "Experimental", "Philosophical", "Male Vocals"],
"Frank Ocean": ["RnB", "Soulful", "Introspective", "Male Vocals"],
"Frank Sinatra": ["1940s Big Band", "Lounge Singer", "Male Vocals"],
"Fred De Palma": ["Reggaeton", "Latin Pop", "Male Vocals"],
"Gary Clark Jr.": ["Blues Rock", "Soulful", "Gritty", "Male Vocals"],
"Gazzelle": ["Indie Pop", "Alternative", "Male Vocals"],
"Genesis": ["Rock", "Progressive", "Art"],
"Geolier": ["Rap", "Neapolitan", "Urban", "Male Vocals"],
"Ghali": ["Trap", "Hip Hop", "Male Vocals"],
"Gianna Nannini": ["Rock", "Energetic", "Female Vocals"],
"Giorgia": ["Pop", "Soul", "Powerful Voice", "Female Vocals"],
"Giusy Ferreri": ["Pop", "Raspy Voice", "Female Vocals"],
"Glass Animals": ["Psychedelic Pop", "Groovy", "Eclectic"],
"Gorillaz": ["Alternative Rock", "Electronic", "Unusual"],
"Green Day": ["Punk Rock", "Aggressive", "Youthful"],
"Grimes": ["Art Pop", "Electronic", "Experimental", "Female Vocals"],
"Guns N' Roses": ["Rock", "Hard Rock", "Energetic"],
"Harry Styles": ["Pop", "Rock", "Groovy", "Male Vocals"],
"Imagine Dragons": ["Rock", "Anthemic", "Emotion"],
"Irama": ["Pop", "Urban", "Male Vocals"],
"Iron Maiden": ["Heavy Metal", "Epic", "Theatrical"],
"J Dilla": ["HipHop", "Soulful", "Experimental", "Male Vocals"],
"Jack White": ["Rock", "Blues", "Raw", "Male Vocals"],
"Jamie xx": ["Electronic", "House", "Minimal"],
"Janelle Monáe": ["Funk", "RnB", "Sci-Fi", "Female Vocals"],
"Janis Joplin": ["Rock", "Blues Rock", "Raw Emotion", "Female Vocals"],
"Jay-Z": ["HipHop", "Aggressive", "Storytelling", "Male Vocals"],
"James Blake": ["Electronic", "Soul", "Minimalist", "Male Vocals"],
"Jimi Hendrix": ["Rock", "Psychedelic", "Guitar Virtuoso", "Male Vocals"],
"Jovanotti": ["Pop Rap", "Worldbeat", "Male Vocals"],
"Judas Priest": ["Heavy Metal", "Power", "Leather"],
"Justin Bieber": ["Pop", "Danceable", "Chillwave", "Male Vocals"],
"Kacey Musgraves": ["Country", "Pop", "Mellifluous", "Female Vocals"],
"Kamasi Washington": ["Jazz", "Fusion", "Epic", "Male Vocals"],
"Kanye West": ["HipHop", "Progressive", "Eclectic", "Male Vocals"],
"Katy Perry": ["Pop", "Glitter", "Festive", "Female Vocals"],
"Kendrick Lamar": ["HipHop", "Lyrical", "Storytelling", "Male Vocals"],
"Kings of Leon": ["Rock", "Emotional", "Raw"],
"Lady Gaga": ["Pop", "Theatrical", "Dance", "Female Vocals"],
"Lana Del Rey": ["Pop", "Sadcore", "Cinematic", "Female Vocals"],
"Laura Pausini": ["Pop", "Ballad", "Female Vocals"],
"Led Zeppelin": ["Hard Rock", "Blues Rock", "Epic"],
"Leon Bridges": ["Soul", "RnB", "Retro", "Male Vocals"],
"Levante": ["Indie Pop", "Alternative", "Female Vocals"],
"Ligabue": ["Rock", "Singer-Songwriter", "Male Vocals"],
"Linkin Park": ["Rock", "Nu Metal", "Emotional"],
"Lizzo": ["Pop", "Funk", "Empowering", "Female Vocals"],
"Lucio Battisti": ["Pop Rock", "Singer-Songwriter", "Innovative", "Male Vocals"],
"Mac DeMarco": ["Indie Pop", "Slacker Rock", "Chill", "Male Vocals"],
"Madame": ["Rap", "Pop", "Female Vocals"],
"Madonna": ["Dance Pop", "High-NRG", "Female Vocals"],
"Mahmood": ["Urban Pop", "RnB", "Male Vocals"],
"Måneskin": ["Rock", "Glam", "Energetic"],
"Maroon 5": ["Pop Rock", "Danceable", "Male Vocals"],
"Marracash": ["Rap", "Urban", "Male Vocals"],
"Marshmello": ["EDM", "Dance", "Happy"],
"Massive Attack": ["Trip Hop", "Dark", "Atmospheric"],
"Metallica": ["Heavy Metal", "Power"],
"MF DOOM": ["HipHop", "Abstract", "Lyrical", "Male Vocals"],
"MGMT": ["Psychedelic Pop", "Electronic", "Playful"],
"Michael Jackson": ["80s Pop", "Dance", "Iconic", "Male Vocals"],
"Miley Cyrus": ["Pop", "Rock", "Party", "Female Vocals"],
"Mina": ["Pop", "Classic Italian", "Powerful Voice", "Female Vocals"],
"Motorhead": ["Heavy Metal", "Rock'n'Roll", "Aggressive"],
"Muse": ["Rock", "Progressive", "Theatrical"],
"Negramaro": ["Pop Rock", "Melodic", "Male Vocals"],
"Nicolas Jaar": ["Electronic", "Experimental", "Atmospheric", "Male Vocals"],
"Nicki Minaj": ["Rap", "Danceable", "Bold", "Female Vocals"],
"Nirvana": ["Grunge", "Dark", "Raw", "Male Vocals"],
"Ozzy Osbourne": ["Heavy Metal", "Dark", "Theatrical", "Male Vocals"],
"Pearl Jam": ["Grunge", "Rock", "Emotional"],
"Phil Collins": ["Pop", "Rock", "Emotional", "Soundtrack", "Male Vocals"],
"Phoebe Bridgers": ["Bedroom", "Grungegaze", "Catchy", "Psychedelic", "Acoustic Tape Recording", "Female Vocals"],
"Pink Floyd": ["Rock", "Progressive", "Atmospheric"],
"Portugal. The Man": ["Alternative Rock", "Psychedelic", "Catchy"],
"Portishead": ["Trip Hop", "Dark", "Cinematic"],
"Post Malone": ["Rap", "Ethereal", "Ambient", "Male Vocals"],
"Prince": ["Funk", "Eclectic", "Glam", "Male Vocals"],
"Queen": ["Rock", "Operatic", "Theatrical", "Male Vocals"],
"Radiohead": ["Alternative Rock", "Experimental", "Atmospheric"],
"Rage Against the Machine": ["Rap Metal", "Political", "Aggressive"],
"Red Hot Chili Peppers": ["Funk Rock", "Stadium", "Heavy Drums"],
"Rihanna": ["RnB", "Dance Pop", "Festive", "Female Vocals"],
"Rkomi": ["Rap", "Pop", "Male Vocals"],
"Rufus Du Sol": ["Electronic", "Dance", "Atmospheric"],
"Sam Smith": ["Soul", "Emotional", "Lounge", "Male Vocals"],
"Sangiovanni": ["Pop", "Urban", "Male Vocals"],
"Sfera Ebbasta": ["Trap", "Hip Hop", "Male Vocals"],
"Shakira": ["Latin", "Dance Pop", "Festive", "Female Vocals"],
"Sigur Rós": ["Post-Rock", "Ethereal", "Atmospheric", "Icelandic"],
"Skrillex": ["Dubstep", "Electronic", "Intense", "Male Vocals"],
"Slayer": ["Thrash Metal", "Aggressive", "Dark"],
"Snoop Dogg": ["Rap", "Funk", "Chill", "Male Vocals"],
"Solange": ["RnB", "Soul", "Artistic", "Female Vocals"],
"Soundgarden": ["90s Grunge", "Heavy", "Dark"],
"St. Vincent": ["Art Rock", "Eclectic", "Unusual", "Female Vocals"],
"Stevie Wonder": ["Soul", "Funk", "Joyful", "Male Vocals"],
"Subsonica": ["Electronic", "Alternative Rock", "Experimental"],
"Sufjan Stevens": ["Indie Folk", "Baroque Pop", "Intimate", "Male Vocals"],
"SZA": ["RnB", "Neo Soul", "Emotional", "Female Vocals"],
"Tame Impala": ["Psychedelic Rock", "Dreamy", "Mellifluous"],
"Tancredi": ["Pop", "Urban", "Male Vocals"],
"Taylor Swift": ["Pop", "Alternative Folk", "Emotional", "Female Vocals"],
"Tedua": ["Rap", "Trap", "Male Vocals"],
"The Beatles": ["60s British Pop", "Classic", "Rock"],
"The Chainsmokers": ["EDM", "Dance", "Party"],
"The Cure": ["Post-Punk", "Gothic", "Emotional"],
"The Doors": ["Rock", "Psychedelic", "Mysterious"],
"The Eagles": ["Rock", "Country Rock", "Harmonious"],
"The Killers": ["Rock", "Synthpop", "Anthemic", "Male Vocals"],
"The Kolors": ["Pop Rock", "Funk", "Male Vocals"],
"The National": ["Indie Rock", "Melancholy", "Introspective"],
"The Rolling Stones": ["Rock", "Blues Rock", "Classic"],
"The Strokes": ["Indie Rock", "Cool", "Raw"],
"The War on Drugs": ["Indie Rock", "Heartland Rock", "Melodic"],
"The Weeknd": ["RnB", "Dark", "Cinematic", "Male Vocals"],
"The Who": ["Rock", "Hard Rock", "Theatrical"],
"The XX": ["Indie Pop", "Minimal", "Atmospheric"],
"Thundercat": ["Funk", "Jazz", "Experimental", "Male Vocals"],
"Tim McGraw": ["Country", "Americana", "Male Vocals"],
"Tiziano Ferro": ["Pop", "RnB", "Emotional", "Male Vocals"],
"Tool": ["Progressive Metal", "Dark", "Complex"],
"Tyler, The Creator": ["HipHop", "Eclectic", "Unusual", "Male Vocals"],
"U2": ["Rock", "Alternative", "Anthemic"],
"Vampire Weekend": ["Indie Rock", "Eclectic", "Upbeat"],
"Vasco Rossi": ["Italian Rock", "Singer-Songwriter", "Male Vocals"],
"Whitney Houston": ["Pop", "RnB", "Emotional", "Female Vocals"],
"Zedd": ["EDM", "Dance Pop", "Energetic", "Male Vocals"],
"Zucchero": ["Blues Rock", "Soul", "Raspy Voice", "Male Vocals"]
}

    };
