import React, { useState, useEffect } from 'react';
import './styles/App.css';
import { metatags as initialMetatags } from './metatagData';
import DesktopView from './components/DesktopView';
import MobileView from './components/MobileView';

function App() {
  const [metatags] = useState(initialMetatags);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');
  const [activeSubcategory, setActiveSubcategory] = useState('');
  const [activeTags, setActiveTags] = useState([]);
  const [songTitle, setSongTitle] = useState('');
  const [musicStyle, setMusicStyle] = useState('');
  const [lyrics, setLyrics] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setCategories(Object.keys(metatags));
    const checkMobile = () => setIsMobile(window.innerWidth <= 640);
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, [metatags]);

  useEffect(() => {
    if (categories.length > 0 && !activeCategory) {
      handleCategoryClick(categories[0]);
    }
  }, [categories, activeCategory]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setActiveSubcategory('');
    
    if (typeof metatags[category] === 'object' && !Array.isArray(metatags[category])) {
      setActiveTags(Object.keys(metatags[category]));
    } else {
      setActiveTags(metatags[category] || []);
    }
  };

  const handleSubcategoryClick = (subcategory) => {
    setActiveSubcategory(subcategory);
    setActiveTags(metatags[activeCategory][subcategory] || []);
  };

  const handleMetatagInsert = (tag) => {
    if (activeCategory === 'Artists' || activeCategory === 'Genre' || activeCategory === 'Style') {
      setMusicStyle(prev => {
        const tags = prev.split(',').map(t => t.trim()).filter(t => t);
        return [...new Set([...tags, tag])].join(', ');
      });
    } else {
      setLyrics(prev => prev + `[${tag}]\n`);
    }
  };

  const handleDragStart = (e, tag) => {
    e.dataTransfer.setData('text/plain', tag);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, field) => {
    e.preventDefault();
    const tag = e.dataTransfer.getData('text/plain');
    if (field === 'style') {
      setMusicStyle(prev => {
        const tags = prev.split(',').map(t => t.trim()).filter(t => t);
        return [...new Set([...tags, tag])].join(', ');
      });
    } else if (field === 'lyrics') {
      setLyrics(prev => prev + `[${tag}]\n`);
    }
  };

  const copyContent = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => alert('Content copied to clipboard!'))
      .catch(err => console.error('Failed to copy: ', err));
  };

  return (
    <div className="app">
      <h1>Suno Meta Tags Music Creator Dashboard</h1>
      
      {isMobile ? (
        <MobileView 
          songTitle={songTitle}
          setSongTitle={setSongTitle}
          musicStyle={musicStyle}
          setMusicStyle={setMusicStyle}
          lyrics={lyrics}
          setLyrics={setLyrics}
          categories={categories}
          activeCategory={activeCategory}
          handleCategoryClick={handleCategoryClick}
          metatags={metatags}
          handleSubcategoryClick={handleSubcategoryClick}
          activeSubcategory={activeSubcategory}
          handleMetatagInsert={handleMetatagInsert}
          copyContent={copyContent}
        />
      ) : (
        <DesktopView 
          categories={categories}
          activeCategory={activeCategory}
          activeSubcategory={activeSubcategory}
          activeTags={activeTags}
          songTitle={songTitle}
          musicStyle={musicStyle}
          lyrics={lyrics}
          handleCategoryClick={handleCategoryClick}
          handleSubcategoryClick={handleSubcategoryClick}
          handleMetatagInsert={handleMetatagInsert}
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          copyContent={copyContent}
          setSongTitle={setSongTitle}
          setMusicStyle={setMusicStyle}
          setLyrics={setLyrics}
          metatags={metatags}
        />
      )}
    </div>
  );
}

export default App;