import React from 'react';

function DesktopView({
  categories,
  activeCategory,
  activeSubcategory,
  activeTags,
  songTitle,
  musicStyle,
  lyrics,
  handleCategoryClick,
  handleSubcategoryClick,
  handleMetatagInsert,
  handleDragStart,
  handleDragOver,
  handleDrop,
  copyContent,
  setSongTitle,
  setMusicStyle,
  setLyrics,
  metatags
}) {
  return (
    <div className="container">
      <div className="content-panel">
        <h2>Welcome to the Suno Meta Tags Creator Dashboard!</h2>
        <p>This powerful tool is designed to help you create and manage metatags for your music effortlessly. With the Suno Meta Tags Creator, you can enhance your songs by adding detailed metadata, improving their discoverability and organization. Whether you are a musician, producer, or content creator, this tool will streamline your workflow, allowing you to focus more on your creativity. Start by entering your song title and music style, and use our extensive library of metatags to add depth and context to your lyrics and music. Make your music stand out with the Suno Meta Tags Creator!</p>
        
        <h2>How to use:</h2>
        <ol>
          <li>Enter your song title and style of music in the fields below.</li>
          <li>Use the "Metatags" section on the right to enhance your song:
            <ul>
              <li>Click on a category to view its specific metatags.</li>
              <li>Drag and drop metatags into the "Style of Music" and "Lyrics" fields, or click to insert them at the cursor position.</li>
            </ul>
          </li>
          <li>Write your lyrics, inserting metatags where appropriate.</li>
          <li>Click "Copy" to copy all the information to your clipboard.</li>
        </ol>

        <h2>Song Content</h2>
        <div className="input-group">
          <input 
            type="text" 
            placeholder="Song Title"
            value={songTitle}
            onChange={(e) => setSongTitle(e.target.value)}
          />
          <button className="copy-btn" onClick={() => copyContent(songTitle)}>Copy</button>
        </div>
        <div className="input-group">
          <textarea 
            placeholder="Style of Music (Drag and drop metatags here)"
            value={musicStyle}
            onChange={(e) => setMusicStyle(e.target.value)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, 'style')}
          />
          <button className="copy-btn" onClick={() => copyContent(musicStyle)}>Copy</button>
        </div>
        <div className="input-group">
          <textarea 
            placeholder="Lyrics and Metatags"
            value={lyrics}
            onChange={(e) => setLyrics(e.target.value)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, 'lyrics')}
          />
          <button className="copy-btn" onClick={() => copyContent(lyrics)}>Copy</button>
        </div>
        <div className="disclaimer">
          <p>All rights to the Suno brand and its trademarks are owned by their respective owners. This tool has been developed by Alessandro Bellucci and all rights to the tool are owned by him. Unauthorized use of this tool is prohibited without express consent.</p>
        </div>
      </div>
      
      <div className="metatags-panel">
        <h2>Metatags</h2>
        <div id="categories">
          {categories.map(category => (
            <button 
              key={category}
              className={`category-btn ${activeCategory === category ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div id="artist-metatags" className="metatags">
          {activeCategory === 'Artists' && activeSubcategory && 
            metatags['Artists'][activeSubcategory].map(tag => (
              <span 
                key={tag} 
                className="metatag"
                draggable="true"
                onDragStart={(e) => handleDragStart(e, tag)}
                onClick={() => handleMetatagInsert(tag)}
              >
                {tag}
                <span className="tooltip">Click or drag and drop to insert</span>
              </span>
            ))
          }
        </div>
        <p id="available-metatags">Available metatags for <span id="activeCategory">{activeSubcategory || activeCategory}</span>:</p>
        <div id="subcategories">
          {activeCategory && typeof metatags[activeCategory] === 'object' && !Array.isArray(metatags[activeCategory]) &&
            Object.keys(metatags[activeCategory]).map(subcategory => (
              <button 
                key={subcategory}
                className={`category-btn ${activeSubcategory === subcategory ? 'active' : ''}`}
                onClick={() => handleSubcategoryClick(subcategory)}
              >
                {subcategory}
              </button>
            ))
          }
        </div>
        <div id="metatags" className="metatags">
          {activeTags.map(tag => (
            <span 
              key={tag} 
              className="metatag"
              draggable="true"
              onDragStart={(e) => handleDragStart(e, tag)}
              onClick={() => handleMetatagInsert(tag)}
            >
              {tag}
              <span className="tooltip">Click or drag and drop to insert</span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DesktopView;