import React, { useState } from 'react';

function MobileView({
  songTitle, setSongTitle,
  musicStyle, setMusicStyle,
  lyrics, setLyrics,
  categories,
  activeCategory,
  handleCategoryClick,
  metatags,
  handleSubcategoryClick,
  activeSubcategory,
  handleMetatagInsert,
  copyContent
}) {
  const [activeTab, setActiveTab] = useState('content');
  const [showIntro, setShowIntro] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState('');

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    setShowModal(true);
  };

  const insertSelected = (target) => {
    handleMetatagInsert(selectedTag);
    setShowModal(false);
  };

  return (
    <div className="mobile-container">
     
      
      <div className="intro-section">
        <button className="toggle-intro" onClick={() => setShowIntro(!showIntro)}>
          {showIntro ? 'Hide' : 'Show'} Introduction
        </button>
        {showIntro && (
          <div id="intro-content">
            <h2>Welcome to the Suno Meta Tags Creator Dashboard!</h2>
            <p>This powerful tool is designed to help you create and manage metatags for your music effortlessly. With the Suno Meta Tags Creator, you can enhance your songs by adding detailed metadata, improving their discoverability and organization. Whether you are a musician, producer, or content creator, this tool will streamline your workflow, allowing you to focus more on your creativity. Start by entering your song title and music style, and use our extensive library of metatags to add depth and context to your lyrics and music. Make your music stand out with the Suno Meta Tags Creator!</p>
            <h3>How to use:</h3>
            <ol>
              <li>Enter the song title and music style in the fields below.</li>
              <li>Use the "Metatags" section to enhance your song:
                <ul>
                  <li>Tap on a category to view its specific metatags.</li>
                  <li>Tap on a metatag to insert it into the "Style of Music" or "Lyrics" fields.</li>
                </ul>
              </li>
              <li>Write your lyrics, inserting metatags where appropriate.</li>
              <li>Tap "Copy" to copy all the information to the clipboard.</li>
            </ol>
          </div>
        )}
      </div>

      <div className="tab-container">
        <button 
          className={`tab ${activeTab === 'content' ? 'active' : ''}`}
          onClick={() => setActiveTab('content')}
        >
          Content
        </button>
        <button 
          className={`tab ${activeTab === 'metatags' ? 'active' : ''}`}
          onClick={() => setActiveTab('metatags')}
        >
          Metatags
        </button>
      </div>

      {activeTab === 'content' && (
        <div id="content-panel" className="content-panel">
          <h2>Song Content</h2>
          <div className="input-group">
            <input
              type="text"
              placeholder="Song Title"
              value={songTitle}
              onChange={(e) => setSongTitle(e.target.value)}
            />
            <button className="copy-btn" onClick={() => copyContent(songTitle)}>Copy Title</button>
          </div>
          <div className="input-group">
            <textarea
              placeholder="Style of Music (Tap metatags to insert)"
              value={musicStyle}
              onChange={(e) => setMusicStyle(e.target.value)}
            />
            <button className="copy-btn" onClick={() => copyContent(musicStyle)}>Copy Style</button>
          </div>
          <div className="input-group">
            <textarea
              placeholder="Lyrics and Metatags"
              value={lyrics}
              onChange={(e) => setLyrics(e.target.value)}
            />
            <button className="copy-btn" onClick={() => copyContent(lyrics)}>Copy Lyrics</button>
          </div>
        </div>
      )}

      {activeTab === 'metatags' && (
        <div id="metatags-panel" className="metatags-panel">
          <h2>Metatags</h2>
          <div id="categories">
            {categories.map(category => (
              <button
                key={category}
                className={`category-btn ${activeCategory === category ? 'active' : ''}`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </button>
            ))}
          </div>
          <div id="artist-metatags" className="metatags">
            {activeCategory === 'Artists' && activeSubcategory && 
              metatags['Artists'][activeSubcategory].map(tag => (
                <span 
                  key={tag} 
                  className="metatag"
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </span>
              ))
            }
          </div>
          <p id="available-metatags">Available metatags for <span id="activeCategory">{activeSubcategory || activeCategory}</span>:</p>
          <div id="subcategories">
            {activeCategory && typeof metatags[activeCategory] === 'object' && !Array.isArray(metatags[activeCategory]) &&
              Object.keys(metatags[activeCategory]).map(subcategory => (
                <button 
                  key={subcategory}
                  className={`category-btn ${activeSubcategory === subcategory ? 'active' : ''}`}
                  onClick={() => handleSubcategoryClick(subcategory)}
                >
                  {subcategory}
                </button>
              ))
            }
          </div>
          <div id="metatags" className="metatags">
            {activeCategory && metatags[activeCategory] && 
              (Array.isArray(metatags[activeCategory]) 
                ? metatags[activeCategory] 
                : (activeSubcategory ? metatags[activeCategory][activeSubcategory] : [])
              ).map(tag => (
                <span 
                  key={tag} 
                  className="metatag"
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </span>
              ))
            }
          </div>
        </div>
      )}

      <div className="disclaimer">
        <p>All rights to the Suno brand and its trademarks are owned by their respective owners. This tool has been developed by Alessandro Bellucci and all rights to the tool are owned by him. Unauthorized use of this tool is prohibited without express consent.</p>
      </div>

      {showModal && (
        <div id="insert-modal" className="modal">
          <div className="modal-content">
            <h3>Insert into:</h3>
            <button className="modal-button" onClick={() => insertSelected('style')}>Style</button>
            <button className="modal-button" onClick={() => insertSelected('lyrics')}>Lyrics</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileView;